<script setup>
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { FACTS_LIST } from '../../../../js/constants'

const store = useStore()

onBeforeMount(() => {
  if(store.state.tempFactsList.length === 0) {
    store.commit('SET_TEMP_FACTS_LIST', FACTS_LIST)
  }
})

</script>

<template>
  <div class="question-box">
    <div class="facts-container">
      <div class="img-container">
        <img src="../../../../assets/images/OpenHomeSmallLogo.png" alt="small-logo">
      </div>
      <div class="text-container"><div class="title">Fun Fact</div> {{ store.state.tempFactsList[0] }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .facts-container {
    display: flex;
    flex-direction: row;
    .img-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        max-height: 45px;
      }
    }
    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      padding-left: 25px;
      .title {
        color: #18459d;
        display: block;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }

  @media (max-width: 430px) {
    .facts-container {
      .img-container {
        display: none;
      }
      .text-container {
        padding-left: 0;
      }
    }
  }
</style>
