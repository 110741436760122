<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { EMAIL_REGEX, ipqsCheckIfEmailInvalid, ipqsCheckIfPhoneIsValid } from '@/js/utils'

import ProgressBar from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentProgressBar'
import FunFacts from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentFunFacts'
import SolarComponentTwilioSmsVerification from '@/components/questions-paths-components/shared-questions-components/SharedComponentTwilioSmsVerification'
import SharedComponentTCPAComplianceText from '@/components/questions-paths-components/shared-questions-components/SharedComponentTCPAComplianceText'

const store = useStore()
const stepName = 'SharedPIICollection'

onMounted(() => {
  if (!window.googleApiLoaded) {
    window.loadGoogleApi(() => {
      setGoogleAutocompleteOnInput(addressInput.value)
    })
  }

  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

const submitButtonText = computed(() => loading.value ? 'Loading ...' : 'Submit')
const hasErrors = computed(
  () =>
    firstName.value.error ||
    lastName.value.error ||
    address.value.error ||
    email.value.error ||
    phone.value.error
)

const loading = ref(false)

const firstName = ref({
  val: '',
  error: false,
  animationError: false
})

const lastName = ref({
  val: '',
  error: false,
  animationError: false
})

const addressInput = ref(null)
const address = ref({
  streetValue: '',
  homeAddress: '',
  streetAddress: '',
  validation: {
    addressValidation: {},
    isValid: false
  },
  error: false,
  animationError: false
})

const email = ref({
  val: '',
  error: false,
  animationError: false
})

const phone = ref({
  val: '',
  error: computed(() => store.state.phoneError),
  animationError: false,
  showModal: computed(() => store.state.twilioSmsVerify.toggleModalWithSmsVerification),
  phoneCodeIsApproved: computed(() => store.state.twilioSmsVerify.approved),
  errorMsg: 'Please enter your phone number',
  ipqsValid: false,
  ipqsFullValid: false
})

const setAnswer = async () => {
  loading.value = true
  await inputValidation()

  if (hasErrors.value) {
    loading.value = false
    return
  }

  const answers = [
    {field: 'first_name', value: firstName.value.val},
    {field: 'last_name', value: lastName.value.val},
    {field: 'email', value: email.value.val},
    {field: 'street' , value: address.value.homeAddress + ' ' + address.value.streetAddress},
    {field: 'phone', value: phone.value.val.replace(/[()\-\s]+/g, '')}
  ]

  store.commit('SET_ARRAY_OF_FIELDS', answers)
  await store.dispatch('showNextFact')
  await store.dispatch('goToNextStep', stepName)

  loading.value = false
}

const addAnimationAndReset = () => {
  const timeOut = 1000
  firstName.value.error ? firstName.value.animationError = true : setTimeout(() => firstName.value.animationError = false, timeOut)
  lastName.value.error ? lastName.value.animationError = true : setTimeout(() => lastName.value.animationError = false, timeOut)
  address.value.error ? address.value.animationError = true : setTimeout(() => address.value.animationError = false, timeOut)
  email.value.error ? email.value.animationError = true : setTimeout(() => email.value.animationError = false, timeOut)
  phone.value.error ? phone.value.animationError = true : setTimeout(() => phone.value.animationError = false, timeOut)
}

const inputValidation = async () => {
  firstName.value.error = firstName.value.val.length === 0
  lastName.value.error = lastName.value.val.length === 0
  await emailValidation()
  addressValidation()
  await phoneValidation()

  addAnimationAndReset()
}

const phoneValidation = async () => {
  if (!phone.value.val || phone.value.val.length < 14 || phone.value.error) {
    store.commit('SET_PHONE_ERROR', true)
    phone.value.errorMsg = 'please enter your phone number'
    return
  }

  if (!phone.value.ipqsValid || !phone.value.ipqsFullValid) {
    const { valid, fullValid } = await ipqsCheckIfPhoneIsValid(phone.value.val)
    phone.value.ipqsValid = valid
    phone.value.ipqsFullValid = fullValid
  }

  if (!phone.value.ipqsFullValid) {
    store.commit('SET_PHONE_ERROR', true)
    phone.value.errorMsg = 'please enter a valid phone number'
  }

  const urlPathsToShowTwilioPhoneVerify = [
    '/solar-savings/v2',
    '/windows/v2',
    '/flooring/v2',
    '/remodel/v2',
    '/roofing/v2'
  ]

  const useTwilioVerifyForUrls = urlPathsToShowTwilioPhoneVerify.some(path => window.location.pathname.includes(path))

  if (store.state.fields.affid !== '23' // we don't validate for WhatIf
    && useTwilioVerifyForUrls
    && !phone.value.phoneCodeIsApproved
    && phone.value.ipqsValid) {
    showTwilioPhoneModal()
  }
}

const showTwilioPhoneModal = () => {
  store.commit('SET_PHONE_ERROR', true)
  phone.value.errorMsg = 'please verify your phone number'

  document.body.classList.add('modal-open')
  store.commit('twilioSmsVerify/TOGGLE_MODAL_WITH_SMS_VERIFICATION')

  if (phone.value.showModal) {
    store.dispatch('twilioSmsVerify/postPhone', phone.value.val)
  }
}

const emailValidation = async () => {
  const validFormat = EMAIL_REGEX.test(String(email.value.val).toLowerCase())

  if (email.value.val.length === 0 || email.value.error) {
    email.value.error = true
    return
  }

  if (validFormat) {
    email.value.error = await ipqsCheckIfEmailInvalid(email.value.val)
  } else {
    email.value.error = true
  }
}

const addressValidation = () => {
  address.value.error = address.value.streetValue.length < 3
  if (address.value.streetValue.length < 3) {
    address.value.error = true
  } else if (!address.value.validation.addressValidation) {
    address.value.error = true
  } else if (!address.value.validation.addressValidation.home) {
    address.value.error = true
  } else {
    address.value.validation.isValid = !!(
      address.value.validation.addressValidation.home ||
      address.value.validation.addressValidation.street
    )
  }
  address.value.error = !address.value.validation.isValid
}

const parseAddressObject = (address_components) => {
  const ShouldBeComponent = {
    home: ["street_number"],
    street: ["street_address", "route"],
  };

  const address = {
    home: "",
    street: ""
  }

  if (address_components !== undefined) {
    address_components.forEach(component => {
      for (let shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          address[shouldBe] = component.long_name;
        }
      }
    })
  }

  return address;
}

const setGoogleAutocompleteOnInput = (addressInput) => {
  const options = {
    componentRestrictions : { country: 'us' },
    fields: ['address_components'],
    types: ['address'],
  }

  const autocomplete = new window.google.maps.places.Autocomplete(addressInput, options)

  autocomplete.addListener("place_changed", () => {
    const place = autocomplete.getPlace()

    const parsedAddress = parseAddressObject(place.address_components)

    if (parsedAddress) {
      address.value.validation.addressValidation = parsedAddress
    }

    address.value.homeAddress = parsedAddress.home
    address.value.streetAddress = parsedAddress.street
    address.value.streetValue =  addressInput.value
  })
}

//handlers
const firstNameHandler = () => {
  firstName.value.error = false
}

const lastNameHandler = () => {
  lastName.value.error = false
}

const addressHandler = () => {
  address.value.error = false
  address.value.validation.isValid = false
  address.value.validation.addressValidation = {}
}

const emailHandler = () => {
  email.value.error = false
}

const phoneHandler = () => {
  phone.value.ipqsValid = false
  phone.value.ipqsFullValid = false
  store.commit('twilioSmsVerify/RESET_TWILIO')
  store.commit('SET_PHONE_ERROR', false)
}
</script>

<template>
<div id="component-height" class="container">
  <SolarComponentTwilioSmsVerification v-if="phone.showModal" />
  <div class="question-box">
    <ProgressBar/>
    <h2 class="question">Complete the form below to calculate your solar <span>savings!</span></h2>
    <div class="answers">
      <div class="columns-container">
        <div class="column">
          <div class="input-container">
            <input
              :disabled="loading"
              v-model="firstName.val"
              v-maska="{ mask: 'S*', tokens: { 'S': { pattern: /[a-zA-Z().\-'\s`]/ }}}"
              :class="{'shake-error' : firstName.animationError, 'blink-error' : firstName.error}"
              @keyup.enter="setAnswer"
              @input="firstNameHandler"
              type="text"
              class="input"
              placeholder="First Name"
            />
            <div class="error-msg">
              <span v-if="firstName.error">First name is invalid</span>
            </div>
          </div>
          <div class="input-container">
            <input
              :disabled="loading"
              v-model="lastName.val"
              v-maska="{ mask: 'S*', tokens: { 'S': { pattern: /[a-zA-Z().\-'\s`]/ }}}"
              :class="{'shake-error' : lastName.animationError, 'blink-error' : lastName.error}"
              @keyup.enter="setAnswer"
              @input="lastNameHandler"
              type="text"
              class="input"
              placeholder="Last Name"
            />
            <div class="error-msg">
              <span v-if="lastName.error">Last name is invalid</span>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="input-container">
            <input
              :disabled="loading"
              v-model="email.val"
              @keyup.enter="setAnswer"
              @input="emailHandler"
              :class="{'shake-error' : email.animationError, 'blink-error' : email.error}"
              type="text"
              class="input"
              placeholder="Email Address"
            />
            <div class="error-msg">
              <span v-if="email.error">Email address is invalid</span>
            </div>
          </div>
          <div class="input-container">
            <input
              :disabled="loading"
              v-model="phone.val"
              v-maska="'(###) ###-####'"
              @keyup.enter="setAnswer"
              @input="phoneHandler"
              :class="{'shake-error' : phone.animationError, 'blink-error' : phone.error}"
              type="text"
              class="input"
              placeholder="Phone number"
            />
            <div class="error-msg">
              <span v-if="phone.error">{{ phone.errorMsg }}</span>
            </div>
          </div>
        </div>
      </div>

      <input
        v-model="address.streetValue"
        @input="addressHandler"
        @keyup.enter="setAnswer"
        :class="{'shake-error' : address.animationError, 'blink-error' : address.error}"
        :disabled="loading"
        ref="addressInput"
        class="input"
        placeholder="Address"
        type="text"
      />
      <div class="error-msg">
        <span v-if="address.error">Address is invalid</span>
      </div>

      <SharedComponentTCPAComplianceText/>

      <button
        :disabled="loading"
        @click="setAnswer"
      >
        {{ submitButtonText }}
      </button>
    </div>
  </div>
  <FunFacts/>
</div>
</template>

<style scoped lang="scss">
@import "../../../assets/style/main";
.question {
  font-weight: 400;
  span {
    color: #00b400;
    font-weight: 800;
  }
}

.columns-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.input, .error-msg {
  max-width: 100%;
}
</style>
