<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import ProgressBar from '../solar-components/SolarComponentProgressBar'
import FunFacts from '../solar-components/SolarComponentFunFacts'

const ANSWERS = {
  'PSE&G': [{ field: 'energy_provider', value: 'PSE&G' }],
  'Con Edison': [{ field: 'energy_provider', value: 'Con Edison' }],
  'Duke Energy': [{ field: 'energy_provider', value: 'Duke Energy' }],
  'National Grid': [{ field: 'energy_provider', value: 'National Grid' }],
  'Other': [{ field: 'energy_provider', value: 'Other' }],
  'None': [{ field: 'energy_provider', value: 'None' }]
}
const stepName = 'SolarQuestionEnergyProvider'
const store = useStore()
const props = defineProps({
  progress: Number
})

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

function setAnswer(answer) {
  store.commit('SET_ARRAY_OF_FIELDS', answer)
  store.dispatch('showNextFact')
  store.dispatch('solarFlow/goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div  class="question-box">
      <ProgressBar />
      <h2 class="question">Who is your energy provider?</h2>
      <div class="answers">
        <button
            v-for="(object, key) in ANSWERS"
            :value="object[0].value"
            @click="setAnswer(object)"
        >
          {{key}}
        </button>
      </div>
    </div>
    <FunFacts />
  </div>
</template>