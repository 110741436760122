<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import ProgressBar from '../solar-components/SolarComponentProgressBar'
import FunFacts from '../solar-components/SolarComponentFunFacts'

const ANSWERS = {
  '1' : [
    { field: 'house_size', value: '1 Bedroom' }
  ],
  '2' : [
    { field: 'house_size', value: '1-2 Bedroom' }
  ],
  '3' : [
    { field: 'house_size', value: '2-3 Bedroom' }
  ],
  '4+' : [
    { field: 'house_size', value: '4 or more Bedroom' }
  ]
}
const store = useStore()
const stepName = 'SolarQuestionHomeSize'
const props = defineProps({
  progress: Number
})

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

function setAnswer(answer) {
  store.commit('SET_ARRAY_OF_FIELDS', answer)
  store.dispatch('showNextFact')
  store.dispatch('solarFlow/goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div class="question-box">
      <ProgressBar />
      <h2 class="question">How many bedrooms are in your home?</h2>
      <div class="answers">
        <button
            v-for="(object, key) in ANSWERS"
            :value="object[0].value"
            @click="setAnswer(object)"
        >
          {{key}}
        </button>
      </div>
    </div>
    <FunFacts />
  </div>
</template>