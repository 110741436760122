
<script setup>
import { onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { zipLookup } from '@/js/utils'
import ProgressBar from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentProgressBar'
import FunFacts from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentFunFacts'
import QuestionInfo from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentQuestionInformation'

const stepName = 'SharedQuestionZip'
const store = useStore()
const props = defineProps({
  progress: Number
})

const state = reactive({
  buttonValue: 'Calculate Savings',
  zipCodeValue: '',
  zipError: false,
  zipErrorAnimation: false,
  city: '',
  state: '',
  county: ''
})
onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })

  if (typeof store.state.fields.zip_code !== 'undefined') {
    state.zipCodeValue = store.state.fields.zip_code
  }
})

watch(() => [state.zipCodeValue], () => {
  if (state.zipCodeValue.length > 0) state.zipError = false
})

async function zipValidation() {
  if (!state.zipCodeValue || state.zipCodeValue.length < 5 || state.zipError) {
    state.zipError = true
    return
  }

  const zipData = await zipLookup(state.zipCodeValue)
  state.zipError = !zipData.zip;

  if (!state.zipError) {
    state.city = zipData.city
    state.state = zipData.state
    state.county = zipData.county
  }
}

function addAnimationAndReset() {
  // Reset the shake animation for zip animation
  if (state.zipError) {
    state.zipErrorAnimation = true
    setTimeout(() => {
      state.zipErrorAnimation = false
    }, 1000);
  }
}

async function checkForErrors() {
  await zipValidation()

  addAnimationAndReset()

  if (state.zipError) state.buttonValue = 'Calculate Savings'

  return state.zipError
}

async function setAnswer() {
  state.buttonValue = 'Loading ...'

  if (state.zipError) state.buttonValue = 'Calculate Savings'
  if (await checkForErrors()) return

  if (!state.zipError) {
    store.commit('SET_ARRAY_OF_FIELDS', [
      {field: 'zip_code', value: state.zipCodeValue},
      {field: 'city', value: state.city},
      {field: 'state', value: state.state},
      {field: 'county', value: state.county}
    ])

    await store.dispatch('showNextFact')
    await store.dispatch('goToNextStep', stepName)
  }
}
</script>

<template>
  <div id="component-height" class="container">
    <div class="question-box">
      <ProgressBar />
      <h2 class="question">Please enter your zip code to see if you qualify!</h2>
      <div class="answers">
        <input
            :disabled="state.buttonValue === 'Loading ...'"
            v-model="state.zipCodeValue"
            @keyup.enter="setAnswer"
            :class="{
              'shake-error': state.zipErrorAnimation,
              'blink-error': state.zipError
            }"
            v-maska="'#####'"
            class="input"
            placeholder="Zip code"
        />
        <div class="error-msg">
          <span v-if="state.zipError">zip code is invalid</span>
        </div>
        <button
            :disabled="state.buttonValue === 'Loading ...'"
            @click="setAnswer"
        >
          {{ state.buttonValue }}
        </button>
      </div>
      <QuestionInfo :info-text="'Check if your state qualifies for federal solar tax incentives & rebates for savings.'"/>
    </div>
    <FunFacts />
  </div>
</template>

<style lang="scss" scoped>
  @import '../../../assets/style/main';
</style>