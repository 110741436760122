<script setup>
import { useStore } from 'vuex'
import { computed, reactive, watch } from 'vue'

const store = useStore()

const state = reactive({
  verificationCode: '',
  showErrorMsg: false,
  showModal: computed(() => store.state.twilioSmsVerify.toggleModalWithSmsVerification),
  resendCodeCoolDown: computed(() => store.state.twilioSmsVerify.resendCodeCoolDown),
  phoneTwilioVerifiedAndApproved: computed(() => store.state.twilioSmsVerify.approved),
  authenticationLoading: computed(() => store.state.twilioSmsVerify.authenticationLoading)
})

watch(() => [state.verificationCode], () => {
  if (state.verificationCode.length > 0) state.showErrorMsg = false
})

const closeModal = () => {
  document.body.classList.remove('modal-open')
  store.commit('twilioSmsVerify/TOGGLE_MODAL_WITH_SMS_VERIFICATION')
}

const resendCode = () => {
  store.dispatch('twilioSmsVerify/resendCode')
}

const verify = async () => {
  if (state.verificationCode.length < 6) {
    state.showErrorMsg = true
    return
  }

  store.commit('twilioSmsVerify/SET_CODE', state.verificationCode)

  await store.dispatch('twilioSmsVerify/postCode')

  if (state.phoneTwilioVerifiedAndApproved) return

  state.showErrorMsg = true
}
</script>

<template>
  <div class="twilio-modal">
    <div class="twilio-container">
      <img
          src="../../../assets/images/close.svg"
          class="close-modal"
          alt=""
          @click="closeModal"
      >
      <div class="twilio-wrapper">
        <h2 class="twilio-verify-header">
          <img class="sms-svg" src="../../../assets/images/sms.svg" alt="sms-icon">
          Verification code
        </h2>
        <p class="twilio-verify-subheader">
          Enter your verification code that we sent to
          <span class="twilio-phone">{{ store.state.twilioSmsVerify.rawPhone }}</span>
        </p>
        <input
            v-model="state.verificationCode"
            :class="{'border-error': state.showErrorMsg}"
            type="text"
            id="twilio-verify"
            class="form-field twilio-input"
            v-maska="'######'"
            placeholder="000000"
        />
        <div
            class="error-msg twilio-failed-verification"
        >
          <span v-if="state.showErrorMsg">
            Verification failed, please enter the code you've received
          </span>
        </div>
        <div class="resend-verification">
          <span
            @click="resendCode"
            :class="{'disabled-resend-twilio' : state.resendCodeCoolDown}"
          >
            {{ state.resendCodeCoolDown ? 'New code has been sent!' : `Didn't get the code? Send a new one` }}
          </span>
        </div>

        <div class="button-block">
          <button
            @click="verify"
            :class="{ 'btn-disabled': state.authenticationLoading }"
            :disabled="state.authenticationLoading"
            class="question-button small-question-button"
          >
            {{ state.authenticationLoading ? 'Verifying...' : 'Verify' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.twilio-modal {
  background-color: rgba(0,0,0,.4);
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  font-family: "Poppins", sans-serif;

  .twilio-container {
    align-items: center;
    background-color: #fefefe;
    border: 1px solid #888;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    margin: auto;

    .close-modal {
      margin-left: auto;
      height: 25px;
      width: 25px;

      &:hover {
        cursor: pointer;
      }
    }

    .twilio-wrapper {
      width: 80%;
      .twilio-input {
        text-align: left;
        font-family: "Poppins", sans-serif;
        margin: 0 auto;
        font-size: 20px;
        border-radius: 5px;
        max-width: 400px;
        width: 100%;
        border: 2px solid #18469d;
        min-height: 50px;
        color: #18469d;
        padding-left: 10px;
        &.border-error {
          border: 2px solid red;
        }
      }
      .twilio-failed-verification {
        color: #ff0014;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
        min-height: 21px;
      }
      .resend-verification {
        color: #18469d;
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        &:hover {
          color: #153c86;
        }
        .disabled-resend-twilio {
          color: #616161;
          cursor: not-allowed;
        }
      }
      .button-block {
        text-align: center;
        .question-button {
          font-family: "Poppins", sans-serif;
          font-size: 20px;
          margin: 5px auto;
          padding: 10px;
          background: linear-gradient(180deg, #ff001a, #911f2b);
          border: none;
          font-weight: 500;
          border-radius: 5px;
          color: white;
          cursor: pointer;
          transition: all .3s;
          &:hover {
            transform: translate(0, -4px);
            box-shadow: 0 9px 6px -7px black;
          }
          &.btn-disabled {
            opacity: .7;
            cursor: not-allowed;
          }
          &.btn-disabled:hover {
            transform: translate(0, 0);
            box-shadow: none;
          }
        }
      }
      .twilio-verify-header {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        margin-bottom: 12px;
        margin-top: 5px;
        color: #00283f;
        font-weight: 500;
        font-size: 32px;

        .sms-svg {
          height: 35px;
          width: 35px;
          margin-right: 8px;
        }
      }

      .twilio-verify-subheader {
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
        margin-bottom: 12px;
        text-align: left;


        .twilio-phone {
          display: inline-block;
          font-weight: 600;
          letter-spacing: 1px;
        }
      }
    }
  }
}
</style>