<script setup>
import { onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { EMAIL_REGEX, ipqsCheckIfEmailInvalid } from '@/js/utils'
import ProgressBar from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentProgressBar'
import FunFacts from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentFunFacts'
import QuestionInfo from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentQuestionInformation'

const stepName = 'SharedQuestionFirstAndLastName'
const store = useStore()

const state = reactive({
  buttonValue: 'Submit',
  firstNameValue: '',
  firstNameError: false,
  firstNameAnimation:  false,
  lastNameValue: '',
  lastNameError: false,
  lastNameAnimation: false,
  emailValue: '',
  emailError: false,
  emailAnimation: false ,
})

onMounted(() => {
  if (!window.googleApiLoaded) {
    window.loadGoogleApi(() => {})
  }

  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

watch(() => [state.firstNameValue], () => {
  if (state.firstNameValue.length > 0) state.firstNameError = false
})

watch(() => [state.lastNameValue], () => {
  if (state.lastNameValue.length > 0) state.lastNameError = false
})

watch(() => [state.emailValue], () => {
  if (state.emailValue.length > 0) state.emailError = false
})

function addAnimationAndReset() {
  // Reset the shake animation for first name input
  if (state.firstNameError) {
    state.firstNameAnimation = true
    setTimeout(() => {
      state.firstNameAnimation = false
    }, 1000);
  }

  // Reset the shake animation for last name input
  if (state.lastNameError) {
    state.lastNameAnimation = true
    setTimeout(() => {
      state.lastNameAnimation = false
    }, 1000);
  }

  // Reset the shake animation for email input
  if (state.emailError) {
    state.emailAnimation = true
    setTimeout(() => {
      state.emailAnimation = false
    }, 1000);
  }
}

async function inputValidation() {
  state.firstNameError = state.firstNameValue.length === 0
  state.lastNameError = state.lastNameValue.length === 0
  state.emailError = state.emailValue.length === 0 || await emailValidation()

  addAnimationAndReset()
}

async function emailValidation() {
  const validFormat = EMAIL_REGEX.test(String(state.emailValue).toLowerCase())

  if (state.emailValue.length === 0 || state.emailError) {
    state.emailError = true
    return
  }

  if (validFormat) {
    state.emailError = await ipqsCheckIfEmailInvalid(state.emailValue)
  } else {
    state.emailError = true
  }

  return state.emailError
}

async function setAnswer() {
  state.buttonValue = 'Loading...'
  await inputValidation()

  if (state.firstNameError || state.lastNameError || state.emailError) {
    state.buttonValue = 'Submit'
    return
  }

  store.commit('SET_ARRAY_OF_FIELDS', [
    { field: 'first_name', value: state.firstNameValue },
    { field: 'last_name', value: state.lastNameValue },
    { field: 'email', value: state.emailValue }
  ])

  await store.dispatch('showNextFact')
  await store.dispatch('goToNextStep', stepName)
}

const choseTextByFlow = () => {
  let text
  switch (window.location.pathname) {
    case '/solar-savings':
    case '/solar-savings/':
    case '/solar-savings/v2':
    case '/solar-savings/v2/':
      text = 'Complete the form below to calculate your solar savings.'
      break
    case '/windows':
    case '/windows/':
    case '/windows/v2':
    case '/windows/v2/':
      text = 'Complete the form below to calculate your windows savings.'
      break
    case '/flooring':
    case '/flooring/':
    case '/flooring/v2':
    case '/flooring/v2/':
      text = 'Complete the form below to calculate your flooring savings.'
      break
    case '/remodel':
    case '/remodel/':
    case '/remodel/v2':
    case '/remodel/v2/':
      text = 'Complete the form below to calculate your remodel savings.'
      break
    case '/roofing':
    case '/roofing/':
    case '/roofing/v2':
    case '/roofing/v2/':
      text = 'Complete the form below to review roofing options.'
      break
    default:
      text = 'Complete the form below to calculate your savings.'
      break;
  }
  return text
}
</script>

<template>
  <div id="component-height" class="container">
    <div class="question-box">
      <ProgressBar />
      <h2 class="question">{{ choseTextByFlow() }}</h2>
      <div class="answers">
        <input
            :disabled="state.buttonValue === 'Loading ...'"
            v-model="state.firstNameValue"
            v-maska="{ mask: 'S*', tokens: { 'S': { pattern: /[a-zA-Z().\-'\s`]/ }}}"
            @keyup.enter="setAnswer"
            :class="{'shake-error': state.firstNameAnimation, 'blink-error': state.firstNameError}"
            class="input"
            type="text"
            placeholder="First Name"
        />
        <div class="error-msg">
          <span v-if="state.firstNameError">First name is invalid</span>
        </div>
        <input
            :disabled="state.buttonValue === 'Loading ...'"
            v-model="state.lastNameValue"
            v-maska="{ mask: 'S*', tokens: { 'S': { pattern: /[a-zA-Z().\-'\s`]/ }}}"
            @keyup.enter="setAnswer"
            :class="{'shake-error': state.lastNameAnimation, 'blink-error': state.lastNameError}"
            class="input"
            type="text"
            placeholder="Last Name"
        />
        <div class="error-msg">
          <span v-if="state.lastNameError">Last name is invalid</span>
        </div>
        <input
            :disabled="state.buttonValue === 'Loading ...'"
            v-model="state.emailValue"
            @keyup.enter="setAnswer"
            :class="{'shake-error': state.emailAnimation, 'blink-error': state.emailError}"
            class="input"
            placeholder="Email Address"
        />
        <div class="error-msg">
          <span v-if="state.emailError">Email address is invalid</span>
        </div>
        <button
            :disabled="state.buttonValue === 'Loading ...'"
            @click="setAnswer"
        >
          {{ state.buttonValue }}
        </button>
      </div>
      <QuestionInfo :info-text="'We will send your Free Solar Savings Report to the email address of your choice.'"/>
    </div>
    <FunFacts />
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/style/main';
</style>
