<script setup>
import HeaderWithoutButton from '@/components/shared-page-components/HeaderWithoutButton.vue'
import RemodelPath from '@/components/questions-paths-components/roofing-path/RoofingPath'
import TheFooter from '@/components/shared-page-components/TheFooter'
import { useStore } from 'vuex'

const store = useStore()
</script>

<template>
  <div class="main-container">
    <HeaderWithoutButton />
    <div class="questions-container">
      <div class="bg-image"></div>
        <div
            v-if="store.state['isLoading']"
            class="component-loading"
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      <RemodelPath class="question-flow" />
    </div>
  </div>
  <TheFooter />
</template>

<style lang="scss" scoped>
@import '../assets/style/main';
.questions-container {
  .bg-image {
    background: linear-gradient(rgba(24, 70, 157, 0.85), rgba(24, 70, 157, 0.85)), url("../assets/images/bg-small.jpg") no-repeat;
    background-size: cover;
  }
}
</style>