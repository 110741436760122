<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import ProgressBar from '../solar-components/SolarComponentProgressBar'
import FunFacts from '../solar-components/SolarComponentFunFacts'
import QuestionInfo from '../solar-components/SolarComponentQuestionInformation'

const ANSWERS = {
  'Electric Bills too high': [{ field: 'reason_for_solar', value: 'Electric Bills too high' }],
  'Power outages': [{ field: 'reason_for_solar', value: 'Power outages' }],
  'Going Green': [{ field: 'reason_for_solar', value: 'Going Green' }],
  'Improve value of home': [{ field: 'reason_for_solar', value: 'Improve value of home' }],
  'Other': [{ field: 'reason_for_solar', value: 'Other' }]
}
const stepName = 'SolarQuestionReasonForSolar'
const store = useStore()
const props = defineProps({
  progress: Number
})

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

function setAnswer(answer) {
  store.commit('SET_ARRAY_OF_FIELDS', answer)
  store.dispatch('showNextFact')
  store.dispatch('solarFlow/goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div  class="question-box">
      <ProgressBar />
      <h2 class="question">What's the main reason you are interested in solar?</h2>
      <div class="answers">
        <button
          v-for="(object, key) in ANSWERS"
          :value="object[0].value"
          @click="setAnswer(object)"
        >
          {{key}}
        </button>
      </div>
      <QuestionInfo :info-text="'Helps us decide the power & wattage of the solar system that will fit well.'" />
    </div>
    <FunFacts />
  </div>
</template>