
<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { ipqsCheckIfPhoneIsValid } from '@/js/utils'
import SharedComponentTCPAComplianceText from '@/components/questions-paths-components/shared-questions-components/SharedComponentTCPAComplianceText'
import ProgressBar from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentProgressBar'
import FunFacts from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentFunFacts'
import QuestionInfo from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentQuestionInformation'
import SolarComponentTwilioSmsVerification from './SharedComponentTwilioSmsVerification'

const stepName = 'SharedQuestionAddressAndPhone'
const store = useStore()
const props = defineProps({
  progress: Number
})
const state = reactive({
  buttonValue: 'Submit',
  streetValue: '',
  streetError: false,
  streetErrorAnimation: false,
  phoneValue: '',
  phoneError: computed(() => store.state.phoneError),
  phoneErrorAnimation: false,
  showModal: computed(() => store.state.twilioSmsVerify.toggleModalWithSmsVerification),
  phoneCodeIsApproved: computed(() => store.state.twilioSmsVerify.approved),
  phoneErrorMsg: 'please enter your phone number',
  phoneIsIPQSValid: false,
  phoneIsIPQSFullValid: false,
  addressForValidation: {
    address: {},
    isValid: false
  },
  homeAddress: '',
  streetAddress: ''
})

const addressInput = ref(null)

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
  setGoogleAutocompleteOnInput(addressInput.value)
})

watch(() => [state.streetValue], () => {
  if (state.streetValue.length > 0) state.streetError = false
})

watch(() => state.phoneValue,() => {
  if (state.phoneValue.length > 0) {
    state.phoneIsIPQSValid = false
    state.phoneIsIPQSFullValid = false
    store.commit('twilioSmsVerify/RESET_TWILIO')
    store.commit('SET_PHONE_ERROR', false)
  }
})

watch(() => state.phoneCodeIsApproved, () => {
  if (state.phoneCodeIsApproved) store.commit('SET_FIELD', { field: 'sms_verified', value: 'yes' })

  if (!state.streetError &&
    !state.phoneError &&
    state.phoneCodeIsApproved) {
    setAnswer()
  }
})

function streetValidation() {
  state.streetError = state.streetValue.length < 3
  if (state.streetValue.length < 3) {
    state.streetError = true
  } else if (!state.addressForValidation.address) {
    state.streetError = true
  } else if (!state.addressForValidation.address.home) {
    state.streetError = true
  } else {
    state.addressForValidation.isValid = !!(state.addressForValidation.address.home || state.addressForValidation.address.street);
  }

  state.streetError = !state.addressForValidation.isValid;
}

const addressHandler = () => {
  //reset address for validation object everytime user types
  state.addressForValidation.isValid = false
  state.addressForValidation.address = {}
}

//google maps api functions
const parseAddressObject = (address_components) => {
  const ShouldBeComponent = {
    home: ["street_number"],
    street: ["street_address", "route"],
  };

  const address = {
    home: "",
    street: ""
  }

  if (address_components !== undefined) {
    address_components.forEach(component => {
      for (let shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          address[shouldBe] = component.long_name;
        }
      }
    })
  }

  return address;
}

const setGoogleAutocompleteOnInput = (addressInput) => {
  const options = {
    componentRestrictions : { country: 'us' },
    fields: ['address_components'],
    types: ['address'],
  }

  const autocomplete = new window.google.maps.places.Autocomplete(addressInput, options)

  autocomplete.addListener("place_changed", () => {
    const place = autocomplete.getPlace()
    const address = parseAddressObject(place.address_components)

    if (address) {
      state.addressForValidation.address = address
    }

    state.homeAddress = address.home
    state.streetAddress = address.street
    state.streetValue =  addressInput.value
  })
}

async function phoneValidation() {
  if (!state.phoneValue || state.phoneValue.length < 14 || state.phoneError) {
    store.commit('SET_PHONE_ERROR', true)
    state.phoneErrorMsg = 'please enter your phone number'
    return
  }

  if (!state.phoneIsIPQSValid || !state.phoneIsIPQSFullValid) {
    const { valid, fullValid } = await ipqsCheckIfPhoneIsValid(state.phoneValue);
    state.phoneIsIPQSValid = valid
    state.phoneIsIPQSFullValid = fullValid
  }

  if (!state.phoneIsIPQSFullValid) {
    store.commit('SET_PHONE_ERROR', true)
    state.phoneErrorMsg = 'please enter a valid phone number'
  }

  const urlPathsToShowTwilioPhoneVerify = [
    '/solar-savings/v2',
    '/windows/v2',
    '/flooring/v2',
    '/remodel/v2',
    '/roofing/v2'
  ]

  const useTwilioVerifyForUrls = urlPathsToShowTwilioPhoneVerify.some(urlPath => window.location.pathname.includes(urlPath))

  if (store.state.fields.affid !== '23' // we don't validate for WhatIf
    && useTwilioVerifyForUrls
    && !state.phoneCodeIsApproved
    && state.phoneIsIPQSValid) {
    showTwilioPhoneModal()
  }
}

function addAnimationAndReset() {
  // Reset the shake animation for street address animation
  if (state.streetError === true) {
    state.streetErrorAnimation = true
    const resetShakeAnimation =  setTimeout(() => {
      state.streetErrorAnimation = false
      clearTimeout(resetShakeAnimation);
    }, 1000);
  }

  // Reset the shake animation for phone animation
  if (state.phoneError === true) {
    state.phoneErrorAnimation = true
    setTimeout(() => {
      state.phoneErrorAnimation = false
    }, 1000);
  }
}

const showTwilioPhoneModal = () => {
  store.commit('SET_PHONE_ERROR', true)
  state.phoneErrorMsg = 'please verify your phone number'

  document.body.classList.add('modal-open')
  store.commit('twilioSmsVerify/TOGGLE_MODAL_WITH_SMS_VERIFICATION')

  if (state.showModal) {
    store.dispatch('twilioSmsVerify/postPhone', state.phoneValue)
  }
}

async function checkForErrors() {
  streetValidation()

  await Promise.all([ phoneValidation() ])

  addAnimationAndReset()
  if (state.phoneError || state.streetError) state.buttonValue = 'Submit'
  return state.phoneError || state.streetError
}

async function setAnswer() {
  state.buttonValue = 'Loading ...'

  if (await checkForErrors()) return

  if (!state.phoneError) {
    store.commit('SET_ARRAY_OF_FIELDS', [
      {field: 'street', value: state.homeAddress + ' ' + state.streetAddress},
      {field: 'phone', value: state.phoneValue.replace(/[()\-\s]+/g, '')},
    ])

    await store.dispatch('showNextFact')
    await store.dispatch('goToNextStep', stepName)
  }

  state.buttonValue = 'Submit'
}
</script>

<template>
  <div id="component-height" class="container">
    <SolarComponentTwilioSmsVerification v-if="state.showModal" />
    <div class="question-box">
      <ProgressBar />
      <h2 class="question">Please complete the below to receive your exclusive home improvement quote!</h2>
      <div class="answers">
        <input
            v-model="state.streetValue"
            @input="addressHandler"
            @keyup.enter="setAnswer"
            :class="{'shake-error': state.streetErrorAnimation, 'blink-error': state.streetError}"
            :disabled="state.buttonValue === 'Loading ...'"
            ref="addressInput"
            class="input"
            placeholder="Address"
        />
        <div class="error-msg">
          <span v-if="state.streetError">address is invalid</span>
        </div>
        <input
            :disabled="state.buttonValue === 'Loading ...'"
            v-model="state.phoneValue"
            @keyup.enter="setAnswer"
            :class="{'shake-error': state.phoneErrorAnimation, 'blink-error': state.phoneError}"
            v-maska="'(###) ###-####'"
            class="input"
            placeholder="Phone number"
        />
        <div class="error-msg">
          <span v-if="state.phoneError">{{ state.phoneErrorMsg }}</span>
        </div>
        <SharedComponentTCPAComplianceText />
        <button
            :disabled="state.buttonValue === 'Loading ...'"
            @click="setAnswer"
        >
          {{ state.buttonValue }}
        </button>
      </div>
      <QuestionInfo :info-text="'We will connect you with the local solar installation experts in your area only after your approval.'" />
    </div>
    <FunFacts />
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/style/main';
</style>
