<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import ProgressBar from '../solar-components/SolarComponentProgressBar'
import FunFacts from '../solar-components/SolarComponentFunFacts'
import QuestionInfo from '../solar-components/SolarComponentQuestionInformation'

const ANSWERS = {
  'Not shaded': [{ field: 'roof_shade', value: 'Not shaded' }],
  'Slightly Shaded': [{ field: 'roof_shade', value: 'Slightly Shaded' }],
  'Completely shaded': [{ field: 'roof_shade', value: 'Completely shaded' }]
}
const stepName = 'SolarQuestionRoofShade'
const store = useStore()
const props = defineProps({
  progress: Number
})

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

function setAnswer(answer) {
  store.commit('SET_ARRAY_OF_FIELDS', answer)
  store.dispatch('showNextFact')
  store.dispatch('solarFlow/goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div  class="question-box">
      <ProgressBar />
      <h2 class="question">Is your roof shaded by trees?</h2>
      <div class="answers">
        <button
          v-for="(object, key) in ANSWERS"
          :value="object[0].value"
          @click="setAnswer(object)"
        >
          {{key}}
        </button>
      </div>
      <QuestionInfo :info-text="'Tree shading helps installers determine the choice of solar system installation.'" />
    </div>
    <FunFacts />
  </div>
</template>