<script setup>
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'
import { FLOW_STEPS } from '@/js/steps-for-flows'

const store = useStore();
const currentStep = computed(() => store.state.currentStep)
const state = reactive({
  minHeight: computed(() => store.state.minHeightOfTheLastComponent)
})

onBeforeMount(() => {
  store.dispatch('init')
  store.dispatch('remodelFlow/goToNextStep', 'StartPath')
})
</script>

<template>
  <div :style="{'min-height': state.minHeight + 'px'}" >
    <transition name="component-fade" mode="out-in">
      <component
        :is="FLOW_STEPS[currentStep].component"
      />
    </transition>
  </div>
</template>