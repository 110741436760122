<script setup>
import { onBeforeMount, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const tapStoneAds = document.getElementById('tap-stone-ads').innerHTML

let ANSWERS = {
  'Solar' : [
    { field: 'home_improvement_project', value: 'Solar' }
  ],
  'Windows' : [
    { field: 'home_improvement_project', value: 'Windows' }
  ],
  'Roofing' : [
    { field: 'home_improvement_project', value: 'Roofing' }
  ],
  'Remodel' : [
    { field: 'home_improvement_project', value: 'Remodel' }
  ],
  'Flooring' : [
    { field: 'home_improvement_project', value: 'Flooring' }
  ]
}

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

onBeforeMount(() => {
  removeImprovementProjectFromAnswersIfAlreadyVisited()
})

function removeImprovementProjectFromAnswersIfAlreadyVisited() {
  for (const [key, value] of Object.entries(store.state.visitedFlows)) {
    if (value) {
      delete ANSWERS[key[0].toUpperCase() + key.slice(1)]
    }
  }
}

function setAnswer(answer) {
  store.dispatch('goToAnotherFlow', answer[0].value.toLowerCase())
}
</script>

<template>
  <div id="component-height" class="container">
    <div class="question-box">
      <div class="check-container">
        <img src="../../../assets/images/check.svg" alt="check-sing">
      </div>
      <h2 class="question" style="margin-bottom: 25px">
        Congrats, you qualify! A local home pro will be in touch with you soon.
      </h2>
      <h2
        v-if="Object.keys(ANSWERS).length !== 0"
        class="question"
      >
        Are you interested in any other home improvement projects?
      </h2>
      <div class="answers">
        <button
          v-for="(object, key) in ANSWERS"
          :value="object[0].value"
          @click="setAnswer(object)"
        >
          {{key}}
        </button>
      </div>
    </div>
    <div class="question-box" v-html="tapStoneAds"></div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/style/main';
.question-box:first-child {
  margin-bottom: 0;
  .check-container {
    border: 5px solid;
    border-radius: 50px;
    padding: 5px;
    display: inline-flex;
    margin-bottom: 25px;
    filter: invert(42%) sepia(99%) saturate(1582%) hue-rotate(110deg) brightness(98%) contrast(104%);
  }
}
button.multiple-check-box-buttons {
  font-size: 18px;
  text-align: left;
  padding: 10px 10px;
  &:hover {
    transform: translate(0, 0px);
    box-shadow: 0 0 0 0 black;
  }
  .check-box {
    display: inline-block;
    border: 1px solid white;
    border-radius: 50px;
    padding: 0 5px;
    background: white;
    margin-right: 5px;
    min-height: 28px;
    min-width: 28px;
    max-height: 28px;
    vertical-align: text-bottom;
    .check-sign {
      vertical-align: middle;
      height: 16px;
      filter: invert(17%) sepia(86%) saturate(2309%) hue-rotate(212deg) brightness(91%) contrast(88%);
    }
  }
}
button.continue-button {
  margin-top: 20px;
}
img {
  max-width: 40px;
  margin: auto;
  filter: invert(18%) sepia(45%) saturate(4681%) hue-rotate(214deg) brightness(86%) contrast(86%);
}

@media only screen and (max-width: 1021px) {
  h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 770px) {
  h1 {
    font-size: 25px;
  }
}
</style>
