<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import ProgressBar from '../solar-components/SolarComponentProgressBar'
import FunFacts from '../solar-components/SolarComponentFunFacts'
import QuestionInfo from '../solar-components/SolarComponentQuestionInformation'

const stepName = 'SolarQuestionEnergyBill'
const ANSWERS = {
  'Less than $100' : [
    { field: 'monthly_bill', value: 'Less than $100' }
  ],
  '$100 - $200' : [
    { field: 'monthly_bill', value: 'From $100 to $200' }
  ],
  '$200 - $300' : [
    { field: 'monthly_bill', value: 'From $200 to $300' }
  ],
  '$300+' : [
    { field: 'monthly_bill', value: 'More than $300' }
  ]
}
const store = useStore()
const props = defineProps({
  progress: Number
})

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

function setAnswer(answer) {
  store.commit('SET_ARRAY_OF_FIELDS', answer)
  store.dispatch('showNextFact')
  store.dispatch('solarFlow/goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div class="question-box">
      <ProgressBar />
      <h2 class="question">How much is your current energy bill?</h2>
      <div class="answers">
        <button
            v-for="(object, key) in ANSWERS"
            :value="object[0].value"
            @click="setAnswer(object)"
        >
          {{key}}
        </button>
      </div>
      <QuestionInfo :info-text="'Current electric bills help calculate how much you save by switching to solar.'" />
    </div>
    <FunFacts />
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../assets/style/main';

.slider-container {
  max-width: 460px;
  width: 100%;
  margin: 0 auto 30px;
  padding: 0 10px;
  .slider-value {
    font-family: $font-poppins;
    font-size: 30px;
    color: $dark-blue;
  }
}

.slider-red {
  box-shadow: .5px .5px 2px 1px rgba(0,0,0,.32);
  border-radius: 15px;
  min-height: 25px;
  --slider-height: 25px;
  --slider-connect-bg: #da0a1f;
  --slider-bg: #dedede;

  --slider-handle-width: 30px;
  --slider-handle-height: 30px;
}
</style>