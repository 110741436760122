<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import ProgressBar from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentProgressBar'

const ANSWERS = {
  'Yes': [{ field: 'flooring_replacement', value: 'yes' }],
  'No': [{ field: 'flooring_replacement', value: 'no' }]
}
const stepName = 'FlooringQuestionReplaceOrRefurbishFloor'
const store = useStore()

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

function setAnswer(answer) {
  store.commit('SET_ARRAY_OF_FIELDS', answer)
  store.dispatch('flooringFlow/goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div  class="question-box">
      <ProgressBar />
      <h2 class="question">Do you want to replace your floors?</h2>
      <div class="answers">
        <button
          v-for="(object, key) in ANSWERS"
          :value="object[0].value"
          @click="setAnswer(object)"
        >
          {{key}}
        </button>
      </div>
    </div>

  </div>
</template>