<script setup>
import {computed, reactive} from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const state = reactive({
  progress: computed(() => store.state.progress)
})

</script>

<template>
  <div></div>
  <div class="progress-container">
    <div class="progress" :style="{width: state.progress + '%'}">
      {{ state.progress }}%
    </div>
  </div>
</template>

<style scoped lang="scss">
  .progress-container {
    border-radius: 50px;
    max-width: 400px;
    min-height: auto;
    margin: 0 auto 15px auto;
    font-family: "Poppins", sans-serif;;
    overflow: hidden;
    background: #d3dfff;
    .progress {
      border-radius: 50px;
      background-image: linear-gradient(to left, #18469d, #2254b5, #2b62cd, #3370e6, #3b7fff);
      height: 100%;
      padding-right: 6px;
      text-align: right;
      color: white;
      font-size: 12px;
      transition: 3ms all;
    }
  }
</style>