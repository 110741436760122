<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import ProgressBar from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentProgressBar'

const ANSWERS = {
  Immediately: [{ field: 'time_frame', value: 'Immediate' }],
  'Within one month': [{ field: 'time_frame', value: 'Within 1 Month' }],
  'Over one month': [{ field: 'time_frame', value: 'More Than 1 Month' }]
}
const stepName = 'SharedQuestionWhenWouldYouLikeToCompleteThisProject'
const store = useStore()
let disableButton = false

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

function setAnswer(answer) {
  store.commit('SET_ARRAY_OF_FIELDS', answer)

  if (disableButton) return
  disableButton = true

  store.dispatch('goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div  class="question-box">
      <ProgressBar />
      <h2 class="question">When would you like to complete this project?</h2>
      <div class="answers">
        <button
            v-for="(object, key) in ANSWERS"
            :value="object[0].value"
            @click="setAnswer(object)"
        >
          {{key}}
        </button>
      </div>
    </div>
  </div>
</template>