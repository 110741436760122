<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import ProgressBar from '../solar-components/SolarComponentProgressBar'
import FunFacts from '../solar-components/SolarComponentFunFacts'
import QuestionInfo from '../solar-components/SolarComponentQuestionInformation'

const ANSWERS = {
  'Single Family Home': [{ field: 'home_type', value: 'Single Family Home' }],
  'Multi Family Home': [{ field: 'home_type', value: 'Multi Family Home' }],
  'Mobile Home': [{ field: 'home_type', value: 'Mobile Home' }],
  'Apartment/Condo': [{ field: 'home_type', value: 'Apartment/Condo' }]
}
const stepName = 'SolarQuestionHomeType'
const store = useStore()
const props = defineProps({
  progress: Number
})

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

function setAnswer(answer) {
  store.commit('SET_ARRAY_OF_FIELDS', answer)
  store.commit('SET_FIELD', { field: 'homeowner', value: 'Yes' })
  store.dispatch('showNextFact')
  store.dispatch('solarFlow/goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div  class="question-box">
      <ProgressBar />
      <h2 class="question">What type of home do you live in?</h2>
      <div class="answers">
        <button
          v-for="(object, key) in ANSWERS"
          :value="object[0].value"
          @click="setAnswer(object)"
        >
          {{key}}
        </button>
      </div>
      <QuestionInfo :info-text="'Your house type helps us decide what solar system may work best for your needs.'" />
    </div>
    <FunFacts />
  </div>
</template>