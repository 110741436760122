<script setup>
import { onMounted, reactive, watch } from 'vue'
import { useStore} from 'vuex'
import ProgressBar from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentProgressBar'
import FunFacts from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentFunFacts'
import { MONTH_LIST } from '@/js/constants'
import { isExists, format } from 'date-fns'

const stepName = 'SharedQuestionDateOfBirth'
const store = useStore()
const state = reactive({
  month: '0',
  monthError: false,
  animationMonth: false,
  day: '',
  dayError: false,
  animationDay: false,
  year: '',
  yearError: false,
  animationYear: false,
  dateError: false,
})

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

watch(() => state.day, () => {
  if (state.day !== '') state.dayError = false
})

watch(() => state.year, () => {
  if (state.year !== '') state.yearError = false
})

watch(() => [state.year, state.day], () => {
    state.dateError = false
})

function addAnimationAndReset() {
  // Reset the shake animation
  if (state.dayError === true) {
    state.animationDay = true
    setTimeout(() => {
      state.animationDay = false
    }, 1000);
  }

  // Reset the shake animation
  if (state.yearError === true) {
    state.animationYear = true
    setTimeout(() => {
      state.animationYear = false
    }, 1000);
  }
}

function dayValidation() {
  if (state.day === '' || state.day == null) {
    state.dayError = true
    addAnimationAndReset()
  }
}

function yearValidation() {
  if (state.year === '' || state.month == null || state.year.length < 4) {
    state.yearError = true
    addAnimationAndReset()
  }
}

function dateValidation() {
  if (state.dayError || state.yearError) return

  const isDateValid = isExists(parseInt(state.year), parseInt(state.month), parseInt(state.day))

  if (!isDateValid) {
    state.dateError = true
  }
}

function validateBirthDate() {
  dayValidation()
  yearValidation()
  dateValidation()
}

function setAnswer() {
  validateBirthDate()

  if (state.dayError || state.yearError || state.dateError) return

  store.commit('SET_FIELD', { field: 'birth_date', value: format(new Date(parseInt(state.year), parseInt(state.month), parseInt(state.day)), 'yyyy/MM/dd') })
  store.dispatch('showNextFact')
  store.dispatch('goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div class="question-box">
      <ProgressBar />
      <h2 class="question">What is your date of birth?</h2>
      <div class="answers">
        <div class="container-dob">
          <div class="box">
            <label>Month</label>
            <select
              v-model="state.month"
              :class="{ 'blink-error': state.dateError }"
              class="input month"
            >
              <option
                v-for="(value, key) in MONTH_LIST"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
          </div>
          <div class="box">
            <label>Day</label>
            <input
              v-model="state.day"
              v-maska="'##'"
              :class="{
                'shake-error': state.animationDay,
                'blink-error': state.dayError || state.dateError
              }"
              class="input day"
              type="text"
              placeholder="dd"
            >
          </div>
          <div class="box">
            <label>Year</label>
            <input
              v-model="state.year"
              v-maska="'####'"
              @keyup.enter="setAnswer"
              :class="{
                'shake-error': state.animationYear,
                'blink-error': state.yearError || state.dateError
              }"
              class="input year"
              type="text"
              placeholder="yyyy"
            >
          </div>
        </div>
        <div class="error-msg">
          <span v-if="state.monthError || state.dayError || state.yearError || state.dateError">please select a valid date of birth</span>
        </div>
        <button
            @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
    <FunFacts />
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/style/main';
.container-dob {
  display: flex;
  max-width: 400px;
  margin: auto;
  .box {
    max-width: 70px;
    &:first-child {
      max-width: 180px;
      margin-right: 8px;
    }
    &:last-child {
      max-width: 90px;
      margin-left: 8px;
    }
  }
}
.input {
  font-family: $font-poppins;
  font-size: 20px;
  border-radius: 5px;
  width: 100%;
  border: 2px solid $dark-blue;
  min-height: 50px;
  color: $dark-blue;
  padding: 1px 5px;
  text-align: center;
  &:focus {
    outline: none;
  }
  &.blink-error {
    background-color: #f8cdcd;
    border: 2px #ff0000 solid;
    animation: blink 1s;
    animation-iteration-count: 4;
  }
  &.shake-error {
    animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  }
}
.error-msg {
  color: #ff0014;
  font-size: 14px;
  font-weight: 500;
  min-height: 21px;
  margin-bottom: 8px;
  text-align: center;
}

@media (max-width: 400px) {
  .container-dob {
    display: inline-block;
    .box {
      max-width: calc(50% - 8px);
      display: inline-block;
      margin-top: 8px;
      &:first-child {
        margin: 0;
        max-width: 100%;
        display: block;
      }
      &:last-child {
        max-width: calc(50% - 8px);
        margin-left: 16px;
      }
    }
  }
}

@keyframes blink { 50% { border-color:#fff ; }  }
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>