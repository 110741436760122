import SharedQuestionZip from '@/components/questions-paths-components/shared-questions-components/SharedQuestionZip'
import SharedQuestionRentOrHomeowner from '@/components/questions-paths-components/shared-questions-components/SharedQuestionRentOrHomeowner'
import SolarQuestionHomeType from '@/components/questions-paths-components/solar-path/questions-components/SolarQuestionHomeType'
import SolarQuestionRoofShade from '@/components/questions-paths-components/solar-path/questions-components/SolarQuestionRoofShade'
import SolarQuestionEnergyProvider from '@/components/questions-paths-components/solar-path/questions-components/SolarQuestionEnergyProvider'
import SolarQuestionReasonForSolar from '@/components/questions-paths-components/solar-path/questions-components/SolarQuestionReasonForSolar'
import SolarQuestionWhenYouWantToStartSaving from '@/components/questions-paths-components/solar-path/questions-components/SolarQuestionWhenYouWantToStartSaving'
import SolarQuestionHomeSize from '@/components/questions-paths-components/solar-path/questions-components/SolarQuestionHomeSize'
import SolarQuestionEnergyBill from '@/components/questions-paths-components/solar-path/questions-components/SolarQuestionEnergyBill'
import SharedQuestionFirstAndLastName from '@/components/questions-paths-components/shared-questions-components/SharedQuestionFirstAndLastName'
import SharedQuestionDateOfBirth from '@/components/questions-paths-components/shared-questions-components/SharedQuestionDateOfBirth'
import SharedQuestionAddressAndPhone from '@/components/questions-paths-components/shared-questions-components/SharedQuestionAddressAndPhone'
import SharedPIICollection from '@/components/questions-paths-components/shared-questions-components/SharedPIICollection'
import SharedThankYouWithMultiplePaths from '@/components/questions-paths-components/shared-questions-components/SharedThankYouWithMultiplePaths'
import WindowQuestionHowManyWindowsAreInYourHousehold from '@/components/questions-paths-components/window-path/questions/WindowQuestionHowManyWindowsAreInYourHousehold'
import WindowQuestionWhatTypeOfWindowsYouHave from '@/components/questions-paths-components/window-path/questions/WindowQuestionWhatTypeOfWindowsYouHave'
import WindowQuestionWhatWouldYouLikeToHaveDone from '@/components/questions-paths-components/window-path/questions/WindowQuestionWhatWouldYouLikeToHaveDone'
import RemodelQuestionWhatWouldYouHaveRemodeled from '@/components/questions-paths-components/remodel-path/RemodelQuestionWhatWouldYouHaveRemodeled'
import RoofingQuestionWhatWouldYouLikeToHaveDone from '@/components/questions-paths-components/roofing-path/RoofingQuestionWhatWouldYouLikeToHaveDone'
import RoofingQuestionMaterialType from '@/components/questions-paths-components/roofing-path/RoofingQuestionMaterialType'
import SharedQuestionWhenWouldYouLikeToCompleteThisProject from '@/components/questions-paths-components/shared-questions-components/SharedQuestionWhenWouldYouLikeToCompleteThisProject'
import FlooringQuestionReplaceOrRefurbishFloor from '@/components/questions-paths-components/flooring-path/FlooringQuestionReplaceOrRefurbishFloor'


export const FLOW_STEPS = {
  SharedQuestionZip: {
    component: SharedQuestionZip
  },
  SharedQuestionRentOrHomeowner: {
    component: SharedQuestionRentOrHomeowner
  },
  SolarQuestionHomeType: {
    component: SolarQuestionHomeType
  },
  SolarQuestionRoofShade: {
    component: SolarQuestionRoofShade
  },
  SolarQuestionEnergyProvider: {
    component: SolarQuestionEnergyProvider
  },
  SolarQuestionReasonForSolar: {
    component: SolarQuestionReasonForSolar
  },
  SolarQuestionWhenYouWantToStartSaving: {
    component: SolarQuestionWhenYouWantToStartSaving
  },
  SolarQuestionHomeSize: {
    component: SolarQuestionHomeSize
  },
  SolarQuestionEnergyBill: {
    component: SolarQuestionEnergyBill
  },
  SharedQuestionFirstAndLastName: {
    component: SharedQuestionFirstAndLastName
  },
  SharedQuestionDateOfBirth: {
    component: SharedQuestionDateOfBirth
  },
  SharedQuestionAddressAndPhone: {
    component: SharedQuestionAddressAndPhone
  },
  SharedPIICollection: {
    component: SharedPIICollection
  },
  SharedThankYouWithMultiplePaths: {
    component: SharedThankYouWithMultiplePaths
  },
  WindowQuestionHowManyWindowsAreInYourHousehold: {
    component: WindowQuestionHowManyWindowsAreInYourHousehold
  },
  WindowQuestionWhatTypeOfWindowsYouHave: {
    component: WindowQuestionWhatTypeOfWindowsYouHave
  },
  WindowQuestionWhatWouldYouLikeToHaveDone: {
    component: WindowQuestionWhatWouldYouLikeToHaveDone
  },
  RemodelQuestionWhatWouldYouHaveRemodeled: {
    component: RemodelQuestionWhatWouldYouHaveRemodeled
  },
  RoofingQuestionWhatWouldYouLikeToHaveDone: {
    component: RoofingQuestionWhatWouldYouLikeToHaveDone
  },
  RoofingQuestionMaterialType: {
    component: RoofingQuestionMaterialType
  },
  SharedQuestionWhenWouldYouLikeToCompleteThisProject: {
    component: SharedQuestionWhenWouldYouLikeToCompleteThisProject
  },
  FlooringQuestionReplaceOrRefurbishFloor: {
    component: FlooringQuestionReplaceOrRefurbishFloor
  }
}