<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import ProgressBar from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentProgressBar'

const ANSWERS = {
  '1': [{ field: 'number_of_windows', value: '1' }],
  '2': [{ field: 'number_of_windows', value: '2' }],
  '3-5': [{ field: 'number_of_windows', value: '3-5' }],
  '6-9': [{ field: 'number_of_windows', value: '6-9' }],
  '10+': [{ field: 'number_of_windows', value: '10+' }]
}
const stepName = 'WindowQuestionHowManyWindowsAreInYourHousehold'
const store = useStore()

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

function setAnswer(answer) {
  store.commit('SET_ARRAY_OF_FIELDS', answer)
  store.dispatch('windowsFlow/goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div  class="question-box">
      <ProgressBar />
      <h2 class="question">How many windows are in your household?</h2>
      <div class="answers">
        <button
            v-for="(object, key) in ANSWERS"
            :value="object[0].value"
            @click="setAnswer(object)"
        >
          {{key}}
        </button>
      </div>
    </div>
  </div>
</template>